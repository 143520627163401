export default {
  data: function data() {
    return {
      list: [{
        image: require("@/assets/cosmetic/b/1.png")
      }, {
        image: require("@/assets/cosmetic/b/2.png")
      }, {
        image: require("@/assets/cosmetic/b/3.png")
      }]
    };
  }
};