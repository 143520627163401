import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_top = _resolveComponent("top");

  var _component_cosmetic_a = _resolveComponent("cosmetic-a");

  var _component_cosmetic_b = _resolveComponent("cosmetic-b");

  var _component_common_footer1 = _resolveComponent("common-footer1");

  var _component_common_back_top = _resolveComponent("common-back-top");

  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_top), _createVNode(_component_cosmetic_a), _createVNode(_component_cosmetic_b), _createElementVNode("div", {
    style: _normalizeStyle({
      margin: '2rem 0 .40rem',
      height: '.02rem',
      background: '#E5E6EB'
    })
  }, null, 4), _createVNode(_component_common_footer1), _createVNode(_component_common_back_top)]);
}