export default {
  data: function data() {
    return {
      list: [{
        title: "一盒一码",
        c: "一体化RFID电子标签或二维码包装盒，包材厂提前赋码，数据精准对应；",
        icon: require("@/assets/cosmetic/a/icon1.png")
      }, {
        title: "经销防窜",
        c: "产品经销发货数据关联，实现防窜货预警，保障市场价格稳定；",
        icon: require("@/assets/cosmetic/a/icon2.png")
      }, {
        title: "防伪验证",
        c: "消费者用手机触碰产品或者扫描产品二维码快速辨别产品真伪，保护企业品牌；",
        icon: require("@/assets/cosmetic/a/icon3.png")
      }, {
        title: "智能营销",
        c: "消费者扫码可参与营销活动，活动可结合溯源信息灵活设置与维护，实现智能精准市场营销。",
        icon: require("@/assets/cosmetic/a/icon4.png")
      }]
    };
  }
};