import _objectSpread from "D:/CODE/\u5B98\u7F51/www/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import top from "./components/top"; // import hotelA from "./components/hotel-a";

import cosmeticB from "./components/cosmetic-b";
import cosmeticA from "./components/cosmetic-a"; // import hotelD from "./components/hotel-d";

import { mapMutations } from "vuex";
export default {
  metaInfo: {
    title: "酒品防伪溯源",
    // set a title
    meta: [{
      // set meta
      name: "keyWords",
      content: "固定资产,仓储管理,防伪溯源,RFID标签,手持机,固定资产管理方案,RFID手持设备,RFID仓储管理方案,防伪溯源整体方案,常达"
    }, {
      name: "description",
      content: "面对世界经济一体化的发展态势，某某集团不断加强与全球业务伙伴在农产品、 粮油食品、番茄果蔬、饮料、酒业、糖业、饲料、肉食以及生物质能源、地产酒 店、 金融等领域的广泛合作。 "
    }]
  },
  components: {
    top: top,
    // hotelA,
    cosmeticB: cosmeticB,
    cosmeticA: cosmeticA // hotelD,

  },
  data: function data() {
    return {// list: [
      //   {
      //     image: require("@/assets/hotel/swiper2/1.png"),
      //     text: "白酒",
      //     imgOffset: "-30%",
      //     icon: require("@/assets/hotel/swiper2/icon1.png"),
      //     icon1: require("@/assets/hotel/swiper2/icon1c.png"),
      //   },
      //   {
      //     image: require("@/assets/hotel/swiper2/5.jpg"),
      //     text: "化妆品",
      //     imgOffset: "-50%",
      //     icon: require("@/assets/hotel/swiper2/icon5.png"),
      //     icon1: require("@/assets/hotel/swiper2/icon5c.png"),
      //   },
      //   {
      //     image: require("@/assets/hotel/swiper2/2.jpg"),
      //     text: "红酒",
      //     imgOffset: "-10%",
      //     icon: require("@/assets/hotel/swiper2/icon2.png"),
      //     icon1: require("@/assets/hotel/swiper2/icon2c.png"),
      //   },
      // ],
      // recommend: {
      //   title: "产品推荐",
      //   list: [
      //     { image: require("@/assets/hotel/recommend/1.png") },
      //     { image: require("@/assets/hotel/recommend/2.png") },
      //     { image: require("@/assets/hotel/recommend/3.png") },
      //   ],
      // },
    };
  },
  methods: _objectSpread({}, mapMutations(["setHeaderColorBlack", "setHeaderColorWhite"])),
  mounted: function mounted() {
    this.setHeaderColorWhite();
    new Wow.WOW({
      animateClass: "animate__animated",
      offset: 30
    }).init();
  },
  unmounted: function unmounted() {
    this.setHeaderColorBlack();
  }
};